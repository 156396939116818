<template>
  <div class="project">
    <Navigation :bgColor='bgColor' :color='color' />
    <ul>
      <li v-for="item in list" :key="item.id" @click="news(item.id)">
        <p class="one-txt-cut">{{item.name}}</p>
        <p class="one-txt-cut">{{item.desc}}</p>
      </li>
    </ul>
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>



<script>
// import panav from "@/components/pc/nav.vue";
import Navigation from "@/components/web/Navigation.vue";
import horserace from "@/components/pc/horserace.vue";
export default {
  components: {
    Navigation,
    horserace,
  },
  data() {
    return {
      color: "#64471a",
      noticeColor: "#ffd7d6",
      background: "#64471a",
      bgColor: "#ffd7d6",
      list: [],
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    async getNews() {
      const res = await this.axios("/api/index/news");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.list = data;
        console.log(this.list);
      }
    },
    news(id) {
      console.log(id);
      this.$router.push(`/new?id=${id}`);
    },
  },
};
</script>


<style lang="less" scoped>
.project {
  width: 100%;
  background: #ffd7d6;
  padding-top: 54px;
  padding-bottom: 50px;
  min-height: 100vh;
  ul {
    li {
      // height: 54px;
      padding: 21px 20px 21px 20px;
      font-family: Antipol, GLEXME;
      font-size: 18px;
      color: #64471a;
      border-bottom: 1px solid #64471a;
    }
  }
}
</style>
